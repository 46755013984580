import React, { useContext } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import PageContext from '../context/PageContext'
import LocaleContext from '../context/LocaleContext'
import { FAQ_PATH } from '../domain/path'
import Layout from '../components/layout/Layout'
import Seo from '../components/layout/Seo'

type FAQ = {
    id: number
    question: string
    answer: string
    points?: string[]
}

const faqs: FAQ[] = [
    {
        id: 1,
        question: 'What is Telobag?',
        answer: `Launched in 2016, Telobag's main intention is to serve the local Indonesians eco-friendly products that do not pollute the environment. 
        Deeply concerned by climate change, environmental degradation, and resource depletion, Telobag promotes a mission of producing goods that benefit
        humans and at the same time, do not cost the Earth.`,
    },
    {
        id: 2,
        question: 'Why is Telobag different from plastic?',
        answer: `Telobag is made from cassava root which contains 0 microplastic particles. It dissolves in the seas and river and also non-toxic to
        animals and plants. Telobag decomposes with food waste, paper products, or animal waste.`,
    },
    {
        id: 3,
        question: 'Is the product in stock?',
        answer: `We strive to maintain sufficient inventory to meet your needs. In the rare case that a product is out of stock,
        you will be notified at the time of your order.`,
    },
    {
        id: 4,
        question: 'How can I utilise Telobag?',
        answer: `We offer different types to accommodate your needs. Telobag is suitable for fruits and vegetables, for laundry (infection control),
        for kitchen and paper waste (properly drained) and various packaging purposes.`,
    },
    {
        id: 5,
        question: 'How do I store Telobag?',
        answer: 'The optimal storage method is:',
        points: [
            'Avoid direct contact with hot water',
            'Keep away from direct sunlight',
            'Room temperature of 22-30 degree Celcius',
            'Store any unused bag in closed container or in its original packaging it comes with',
        ],
    },
    {
        id: 6,
        question: `Why is Telobag wrapped in plastic packaging?`,
        answer: `As a bio-bag, Telobag is very sensitive to its surroundings. Both the organic and hydrophilic nature of Telobag allow natural degradation through
        consumption by organisms, and dissolving in water respectively. With plastic as its outer wraps, it helps optimise the storage of unused Telobag.`,
    },
    {
        id: 7,
        question: `Does Telobag embrace sustainability as a company?`,
        answer: `We help farmers and local cassava suppliers thrive and ensure the long-term sustainability of the premium products we offer.
        We are 100% committed to offer ethically purchased and responsibly produced sustainable products.`,
    },
    {
        id: 8,
        question: `How long does it take for Telobag to decompose?`,
        answer: `Based upon laboratory reports*, it takes up to 180 days to decompose naturally with nature. 
        It also decomposes along with paper waste and organic waste.`,
    },
    {
        id: 9,
        question: `How much weight can it sustain?`,
        answer: `It depends on the size of the goods. Telobag can sustain up to 3kg of goods in each carrier bag. 
        However, as different goods come in various shapes and sizes, there is no general guideline as to how much weight it can sustain.`,
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function FAQPage() {
    const { locale } = useContext(LocaleContext)
    return (
        <PageContext.Provider
            value={{
                basepath: FAQ_PATH,
                locale,
                localised: false,
            }}
        >
            <Layout>
                <Seo
                    title="Frequently Asked Questions"
                    titleCompany="Telobag Official"
                    description="Information to satisfy your curiosity about Telobag"
                    relativeUrl="/faq"
                />
                {/* <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary">
                    FAQ
                </h1>
                <hr className="border-b border-gainsboro w-10" /> */}

                {/* <div className="mt-12">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 lg:grid-cols-3">
                        {faqs.map(faq => (
                            <div key={faq.id}>
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    {faq.question}
                                </dt>
                                <dd className="mt-2 text-base text-gray-500">
                                    {faq.answer}
                                </dd>
                                <dd>
                                    {!!faq.points && (
                                        <ul className="mt-1 ml-4 list-disc">
                                            {faq.points.map(point => (
                                                <li className="text-gray-500">
                                                    {point}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div> */}
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Frequently asked questions
                    </h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map(faq => (
                            <Disclosure
                                as="div"
                                key={faq.question}
                                className="pt-6"
                            >
                                {({ open }) => (
                                    <>
                                        <dt className="text-lg">
                                            <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                <span className="font-medium text-gray-900">
                                                    {faq.question}
                                                </span>
                                                <span className="ml-6 h-7 flex items-center">
                                                    <ChevronDownIcon
                                                        className={classNames(
                                                            open
                                                                ? '-rotate-180'
                                                                : 'rotate-0',
                                                            'h-6 w-6 transform'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                </span>
                                            </Disclosure.Button>
                                        </dt>
                                        <Disclosure.Panel
                                            as="dd"
                                            className="mt-2 pr-12"
                                        >
                                            <p className="text-base text-gray-500">
                                                {faq.answer}
                                            </p>
                                            {!!faq.points && (
                                                <ul className="mt-1 ml-4 list-disc">
                                                    {faq.points.map(point => (
                                                        <li className="text-gray-500">
                                                            {point}
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default FAQPage
